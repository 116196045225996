import React from 'react';
import { fontSizes, mq, colors } from '../../theme';
import { graphql, useStaticQuery } from 'gatsby';
import WebsiteButton from '../WebsiteButton';
import { HeadingDivider, Heading2 } from '../Heading/Text';
import Container from '../Container';
import Image from '../Image';
import BackgroundImage from '../BackgroundImage';

const Jobs = () => {
  const {
    allJobsJson: { nodes: data }
  } = useStaticQuery(graphql`
    query JobsQuery {
      allJobsJson(limit: 9) {
        nodes {
          id
          name
          image
        }
      }
    }
  `);

  return (
    <div css={{ backgroundColor: colors.neutralWhite }}>
      <Container>
        <div css={mq({ width: '100%' })}>
          <div
            css={mq({
              width: '100%',
              textAlign: ['center', 'left', 'left'],
              marginBottom: 30
            })}
          >
            <Heading2 light maxWidth={620} css={{ margin: 'auto' }}>
              Les métiers compris dans notre bibliothèque d’ouvrages
            </Heading2>
            <HeadingDivider />
          </div>
          <div
            css={mq({
              display: 'flex',
              width: ['50%', '100%', '100%'],
              marginRight: ['50%', 0, 0]
            })}
          >
            <div
              css={mq({
                flex: '45%',
                marginRight: [60, 0, 0],
                textAlign: 'center'
              })}
            >
              <div
                css={{
                  display: 'flex',
                  flexFlow: 'wrap row',
                  justifyContent: 'space-between'
                }}
              >
                {data.map(({ id, name, image }) => (
                  <div
                    key={id}
                    css={{
                      width: '33%',
                      textAlign: 'center',
                      marginBottom: 43
                    }}
                  >
                    <Image alt={name} src={image} width={50} height={50} />
                    <h3
                      css={{
                        margin: 'auto',
                        textTransform: 'uppercase',
                        fontSize: fontSizes.regular,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '90%'
                      }}
                    >
                      {name.split('/').slice(0, 1).join('')}
                    </h3>
                  </div>
                ))}
              </div>
              <WebsiteButton href="/partenaires/#tab-metiers" variant="outline">
                En savoir plus
              </WebsiteButton>
            </div>
          </div>
        </div>

        <div
          css={mq({
            display: ['block', 'none', 'none'],
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: 700
          })}
        >
          <BackgroundImage
            src="nopper.png"
            css={mq({
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'bottom center',
              height: [800, 400, 500]
            })}
          />
        </div>
      </Container>
    </div>
  );
};

export default Jobs;
