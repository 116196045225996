import React, { useRef, useEffect } from 'react';
import { ControlBar } from 'video-react';
import { fontSizes, colors, mq, fontWeights } from '../theme';
import Video from '../components/Video';
import Layout from '../components/Layout';
import Container from '../components/Container';
import SEO from '../components/seo';
import WebsiteButton from '../components/WebsiteButton';
import NextWebinar from '../components/NextWebinar/NextWebinar';
import YourBrands from '../components/YourBrands';
import {
  HeadingDivider,
  Heading2,
  Paragraph
} from '../components/Heading/Text';
import Image from '../components/Image';
import BackgroundImage from '../components/BackgroundImage';
import Jobs from '../components/Jobs';
import VideoPlayer from '../components/VideoPlayer';
import FooterBanner from '../components/FooterBanner/FooterBanner';

const ua =
  typeof navigator !== 'undefined' ? navigator.userAgent.toLowerCase() : '';
const isDesktop = !(
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
    ua
  ) ||
  /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
    ua.substr(0, 4)
  )
);

const LandingPage = () => {
  const videoHeaderRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (isDesktop && videoHeaderRef.current) {
        videoHeaderRef.current.play();
      }
    }, 500);
  }, []);

  return (
    <Layout>
      <SEO
        title="Chiffrage devis et bibliothèque d'ouvrages inégalée"
        description="Le meilleur logiciel de chiffrage spécialisé bâtiment. Gain de temps sur les devis. Précision de commandes matériaux. + de 75000 ouvrages et références…"
      />

      <Container background={colors.neutralWhite}>
        <Container.ContentLeft
          size={[40, 50]}
          css={mq({ marginRight: [60, 0] })}
        >
          <Heading2
            light
            primary
            maxWidth={[460, '100%', '100%']}
            css={mq({ marginTop: [60, 0, 20] })}
          >
            <span>Comment je gagne du temps</span> avec un ouvrage sur
            Web2vi&nbsp;?
          </Heading2>
          <HeadingDivider />
          <Paragraph>
            Le fonctionnement de la bibliothèque d’ouvrages de Web2vi est calqué
            sur la manière de travailler d’un artisan sur le terrain.
          </Paragraph>
          <span
            css={mq({
              display: ['inline', 'none']
            })}
          >
            <WebsiteButton
              href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
              trackLocation="Landing bibliothèque - Module Titre"
            >
              je demande ma démo gratuite
            </WebsiteButton>
          </span>
        </Container.ContentLeft>
        <Container.ContentRight size={[60, 50]} css={{ textAlign: 'center' }}>
          <div css={mq({ marginBottom: 25 })}>
            <div
              css={{
                width: '100%',
                borderRadius: 5,
                boxShadow: `0 13px 46px 0 ${colors.neutralLight}`,
                '.video-react-big-play-button': {
                  display: 'none'
                }
              }}
            >
              <div
                css={{
                  backgroundColor: colors.neutralGray,
                  height: 33,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                  paddingLeft: 18,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {Array.from({ length: 3 }).map((_, index) => (
                  <div
                    key={index}
                    css={{
                      width: 8,
                      height: 8,
                      borderRadius: 8,
                      marginRight: 6,
                      backgroundColor: colors.neutralDark70
                    }}
                  />
                ))}
              </div>

              <div css={mq({ display: ['block', 'none', 'block'] })}>
                <Video ref={videoHeaderRef} loop src="page-landing-biblio.mp4">
                  <ControlBar disableCompletely disableDefaultControls />
                </Video>
              </div>
              <div css={mq({ display: ['none', 'block', 'none'] })}>
                <VideoPlayer
                  ratio={[1920, 1012]}
                  source="page-landing-biblio.mp4"
                  placeholder="poster-mobile-video-biblio.png"
                >
                  <ControlBar disableCompletely disableDefaultControls />
                </VideoPlayer>
              </div>
            </div>
          </div>
          <Paragraph
            css={{
              fontWeight: fontWeights.semiBold
            }}
          >
            Web2vi me donne automatiquement toutes les ressources matériaux et
            main d’oeuvre nécessaires pour la mise en oeuvre.
          </Paragraph>
          <span
            css={mq({
              display: ['none', 'inline']
            })}
          >
            <WebsiteButton
              href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
              trackLocation="Landing bibliothèque - Module Titre"
            >
              je demande ma démo gratuite
            </WebsiteButton>
          </span>
        </Container.ContentRight>
      </Container>

      <Container background={colors.neutralDarker} color={colors.neutralWhite}>
        <Container.ContentLeft
          size={50}
          noMarge
          css={mq({ display: ['block', 'none', 'none'] })}
        >
          <BackgroundImage
            src="fred.png"
            css={{
              width: '100%',
              height: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'bottom 0px right 0px'
            }}
          />
        </Container.ContentLeft>
        <Container.ContentRight size={[50, 100, 100]}>
          <Heading2 maxWidth={[500, '100%', '100%']}>
            Web2vi et <span>sa bibliothèque de prix inégalée</span>
          </Heading2>
          <HeadingDivider />
          <Paragraph>
            Créée spécifiquement pour les pros du bâtiment, l’utilisation de
            notre base de données d’ouvrages est intuitive sur le logiciel de
            chiffrage bâtiment Web2vi.
            <br />
            <br /> Découvrez la bibliothèque de prix du bâtiment la plus
            détaillée et précise du marché. Elle est mise à jour automatiquement
            et gratuitement.
          </Paragraph>
        </Container.ContentRight>
      </Container>

      <NextWebinar />

      <Container
        background={colors.neutralDarker}
        color={colors.neutralWhite}
        css={mq({ flexDirection: 'column', alignItems: 'center' })}
      >
        <Heading2
          css={mq({
            color: colors.brandColor,
            marginBottom: [59, 52],
            textAlign: 'center'
          })}
        >
          La bibliothèque d’ouvrages Web2vi c’est
        </Heading2>
        <div
          css={mq({
            position: 'relative',
            marginBottom: [100, 60],
            width: '90%'
          })}
        >
          <VideoPlayer
            ratio={[1920, 1080]}
            source="page-home-biblio.mp4"
            placeholder="vignette-video-biblio.png"
            trackLabel="Présentation bibliotèque (Landing bibliothèque)"
          />
        </div>
        <div
          css={mq({
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: ['row', 'column'],
            width: ['calc(100% - 160px)', '100%', '100%']
          })}
        >
          {[
            {
              title: '+ de 33 marques',
              subtitle: 'référencées'
            },
            {
              title: '+ de 60 000',
              subtitle: 'matériaux ajoutés'
            },
            {
              title: '+ de 16 métiers',
              subtitle: 'représentés'
            }
          ].map((item, key) => (
            <div
              key={key}
              css={{ textAlign: 'center', textTransform: 'uppercase' }}
            >
              <Image
                alt="tick"
                css={mq({
                  width: 52,
                  height: 44,
                  margin: 'auto',
                  marginBottom: [43, 35]
                })}
                src="tick.svg"
              />
              <h3
                css={mq({
                  letterSpacing: 1,
                  color: colors.brandColor,
                  lineHeight: ['45px', '32px'],
                  marginBottom: [59, 91]
                })}
              >
                {item.title}{' '}
                <div
                  css={mq({
                    fontSize: fontSizes.large,
                    color: colors.neutralWhite,
                    lineHeight: '20px'
                  })}
                >
                  {item.subtitle}
                </div>
              </h3>
            </div>
          ))}
        </div>
      </Container>

      <YourBrands />

      <Jobs />
      <FooterBanner />
    </Layout>
  );
};

export default LandingPage;
